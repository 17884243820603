<apex-box
  #box
  class="no-padding"
  [expanded]="expanded"
  [disabled]="disabled"
  [loading]="loading"
  [icon]="icon">
  <ng-container title>
    {{ title }}
  </ng-container>

  <ng-container description>
    {{ description }}
  </ng-container>

  <ng-container right>
    <button
      [matTooltip]="'Upload' | translate"
      type="button"
      mat-icon-button
      (click)="$event.stopPropagation(); uploadFilesInput.click()">
      <mat-icon>add</mat-icon>
    </button>
  </ng-container>

  <div
    class="file-upload-container"
    (apexDrop)="$event && uploadFilesFromDrop($event)"
    (dragover)="onDragOver($event)"
    (dragleave)="onDragLeave($event)"
    (dragenter)="$event.preventDefault()"
    (click)="uploadFilesInput.click()"
    [ngClass]="{
      'drag-over': dragOver,
      invalid: !validFiles,
    }">
    <ng-container *ngIf="!viewFiles?.length">
      <div class="df c ac jc ap hover">
        <mat-icon
          class="upload-icon-small"
          [inline]="true">
          cloud_upload
        </mat-icon>
        <T str="Drop files or click here to upload files"></T>
      </div>
    </ng-container>

    <ng-container *ngIf="viewFiles?.length">
      <div class="display-grid-cards card-width-150 ap hover">
        <ng-container *ngFor="let viewFile of viewFiles">
          <div
            class="mat-elevation-z2 file-wrapper position-relative"
            (mouseover)="viewFile.hover = true"
            (mouseleave)="viewFile.hover = false"
            (click)="$event.stopPropagation()">
            <ng-container *ngIf="!viewFile.icon; else showIcon">
              <img
                class="object-fit-cover height-100 w-100 no-hover"
                [src]="viewFile.src"
                [alt]="viewFile.file?.name" />
            </ng-container>

            <ng-template #showIcon>
              <div class="df ac jc height-100 width-100">
                <mat-icon> {{ viewFile.icon }}</mat-icon>
              </div>
            </ng-template>

            <div
              class="mat-elevation-z1 mat-caption position-absolute bottom-0 left-0 w-100"
              [matTooltip]="viewFile.file?.name ?? '' | translate">
              <div class="apxs truncate no-hover">
                {{ viewFile.file?.name }}
              </div>
            </div>

            <div class="position-absolute top-0 right-0 amxs">
              <button
                mat-icon-button
                [color]="undefined"
                type="button"
                [matTooltip]="'Remove file' | translate"
                (click)="$event.stopPropagation(); removeFile(viewFile)">
                <mat-icon color="warn">delete</mat-icon>
              </button>
            </div>
          </div>
        </ng-container>
      </div>
    </ng-container>
  </div>
</apex-box>

<input
  #uploadFilesInput
  class="dn"
  type="file"
  multiple
  (change)="uploadFilesFromClick($event)" />
