import { Injectable } from '@angular/core';
import {
  CreateUnwantedIncidentDto,
  CreateUnwantedIncidentProcessActionDto,
  CreateUnwantedIncidentProcessDto,
  Order,
  ResponseNearbyUnwantedIncidentProjectDtoCollectionResponse,
  ResponseUnwantedIncidentDto,
  ResponseUnwantedIncidentDtoPageResponse,
  ResponseUnwantedIncidentProcessActionDto,
  ResponseUnwantedIncidentProcessActionDtoPageResponse,
  ResponseUnwantedIncidentProcessDto,
  ResponseUnwantedIncidentProcessDtoPageResponse,
  ResponseUnwantedIncidentProjectDtoPageResponse,
  UnwantedIncidentApi,
  UnwantedIncidentPageOptionsOrderBy,
  UnwantedIncidentProjectPageOptionsOrderBy,
  UnwantedIncidentType,
  UpdateUnwantedIncidentProcessActionDto,
  UpdateUnwantedIncidentProcessDto,
} from '../../../../../../generated/apex-rest';
import { restAxiosConfig } from '../../utils/rest-axios-config';

@Injectable()
export class UnwantedIncidentService {
  unwantedIncidentApi = new UnwantedIncidentApi(restAxiosConfig());

  async createUnwantedIncidentNonAuth(
    createUnwantedIncidentDto: CreateUnwantedIncidentDto,
  ): Promise<ResponseUnwantedIncidentDto> {
    if (!createUnwantedIncidentDto) {
      throw new Error('unwanted incident is required');
    }

    const response = await this.unwantedIncidentApi.nonAuthUnwantedIncidentControllerCreate(createUnwantedIncidentDto);

    return response.data.Entity;
  }

  async createUnwantedIncident(
    createUnwantedIncidentDto: CreateUnwantedIncidentDto,
  ): Promise<ResponseUnwantedIncidentDto> {
    if (!createUnwantedIncidentDto) {
      throw new Error('unwanted incident is required');
    }

    const response = await this.unwantedIncidentApi.unwantedIncidentControllerCreate(createUnwantedIncidentDto);

    return response.data.Entity;
  }

  async readUnwantedIncident(unwantedIncidentId: string): Promise<ResponseUnwantedIncidentDto> {
    if (!unwantedIncidentId) {
      throw new Error('unwanted incident id is required');
    }

    const response = await this.unwantedIncidentApi.unwantedIncidentControllerRead(unwantedIncidentId);

    return response.data.Entity;
  }

  async readUnwantedIncidents(
    orderBy?: UnwantedIncidentPageOptionsOrderBy,
    order?: 'ASC' | 'DESC',
    page?: number,
    limit?: number,
    search?: string,
    projectId?: number,
    objectId?: number,
    types?: UnwantedIncidentType[],
  ): Promise<ResponseUnwantedIncidentDtoPageResponse> {
    const response = await this.unwantedIncidentApi.unwantedIncidentControllerReadCollection(
      page,
      limit,
      order,
      orderBy,
      projectId,
      objectId,
      types,
      undefined,
      search,
    );

    return response.data;
  }

  async createUnwantedIncidentProcess(
    unwantedIncidentId: string,
    createUnwantedIncidentProcessDto: CreateUnwantedIncidentProcessDto,
  ): Promise<ResponseUnwantedIncidentProcessDto> {
    if (!unwantedIncidentId || !createUnwantedIncidentProcessDto) {
      throw new Error('unwanted incident id and unwanted incident process is required');
    }

    const response = await this.unwantedIncidentApi.unwantedIncidentProcessControllerCreate(
      unwantedIncidentId,
      createUnwantedIncidentProcessDto,
    );

    return response.data.Entity;
  }

  async readUnwantedIncidentProcess(
    unwantedIncidentId: string,
    unwantedIncidentProcessId: string,
  ): Promise<ResponseUnwantedIncidentProcessDto> {
    if (!unwantedIncidentId || !unwantedIncidentProcessId) {
      throw new Error('unwanted incident id and unwanted incident process id is required');
    }

    const response = await this.unwantedIncidentApi.unwantedIncidentProcessControllerRead(
      unwantedIncidentId,
      unwantedIncidentProcessId,
    );

    return response.data.Entity;
  }

  async updateUnwantedIncidentProcess(
    unwantedIncidentId: string,
    unwantedIncidentProcessId: string,
    updateUnwantedIncidentProcessDto: UpdateUnwantedIncidentProcessDto,
  ): Promise<ResponseUnwantedIncidentProcessDto> {
    if (!unwantedIncidentId || !unwantedIncidentProcessId || !updateUnwantedIncidentProcessDto) {
      throw new Error('unwanted incident id, unwanted incident process id and unwanted incident process is required');
    }

    const response = await this.unwantedIncidentApi.unwantedIncidentProcessControllerUpdate(
      unwantedIncidentId,
      unwantedIncidentProcessId,
      updateUnwantedIncidentProcessDto,
    );

    return response.data.Entity;
  }

  async deleteUnwantedIncidentProcess(unwantedIncidentId: string, unwantedIncidentProcessId: string): Promise<boolean> {
    if (!unwantedIncidentId || !unwantedIncidentProcessId) {
      throw new Error('unwanted incident id and unwanted incident process id is required');
    }

    const response = await this.unwantedIncidentApi.unwantedIncidentProcessControllerDelete(
      unwantedIncidentId,
      unwantedIncidentProcessId,
    );

    return response.data.deleted;
  }

  async readUnwantedIncidentProcesses(
    unwantedIncidentId: string,
    orderBy?: 'id' | 'name' | 'createdAt' | 'updatedAt',
    order?: 'ASC' | 'DESC',
    page?: number,
    limit?: number,
    search?: string,
  ): Promise<ResponseUnwantedIncidentProcessDtoPageResponse> {
    if (!unwantedIncidentId) {
      throw new Error('unwanted incident id is required');
    }

    const response = await this.unwantedIncidentApi.unwantedIncidentProcessControllerReadCollection(
      unwantedIncidentId,
      page,
      limit,
      order,
      orderBy,
      search,
    );

    return response.data;
  }

  async createUnwantedIncidentProcessAction(
    unwantedIncidentId: string,
    unwantedIncidentProcessId: string,
    createUnwantedIncidentProcessActionDto: CreateUnwantedIncidentProcessActionDto,
  ): Promise<ResponseUnwantedIncidentProcessActionDto> {
    if (!unwantedIncidentId || !unwantedIncidentProcessId || !createUnwantedIncidentProcessActionDto) {
      throw new Error(
        'unwanted incident id, unwanted incident process id and unwanted incident process action is required',
      );
    }

    const response = await this.unwantedIncidentApi.unwantedIncidentProcessActionControllerCreate(
      unwantedIncidentId,
      unwantedIncidentProcessId,
      createUnwantedIncidentProcessActionDto,
    );

    return response.data.Entity;
  }

  async readUnwantedIncidentProcessAction(
    unwantedIncidentId: string,
    unwantedIncidentProcessId: string,
    unwantedIncidentProcessActionId: string,
  ): Promise<ResponseUnwantedIncidentProcessActionDto> {
    if (!unwantedIncidentId || !unwantedIncidentProcessId || !unwantedIncidentProcessActionId) {
      throw new Error(
        'unwanted incident id, unwanted incident process id and unwanted incident process action id is required',
      );
    }

    const response = await this.unwantedIncidentApi.unwantedIncidentProcessActionControllerRead(
      unwantedIncidentId,
      unwantedIncidentProcessId,
      unwantedIncidentProcessActionId,
    );

    return response.data.Entity;
  }

  async updateUnwantedIncidentProcessAction(
    unwantedIncidentId: string,
    unwantedIncidentProcessId: string,
    unwantedIncidentProcessActionId: string,
    updateUnwantedIncidentProcessActionDto: UpdateUnwantedIncidentProcessActionDto,
  ): Promise<ResponseUnwantedIncidentProcessActionDto> {
    if (
      !unwantedIncidentId ||
      !unwantedIncidentProcessId ||
      !unwantedIncidentProcessActionId ||
      !updateUnwantedIncidentProcessActionDto
    ) {
      throw new Error(
        'unwanted incident id, unwanted incident process idm unwanted incident process action id, and unwanted incident process action is required',
      );
    }

    const response = await this.unwantedIncidentApi.unwantedIncidentProcessActionControllerUpdate(
      unwantedIncidentId,
      unwantedIncidentProcessId,
      unwantedIncidentProcessActionId,
      updateUnwantedIncidentProcessActionDto,
    );

    return response.data.Entity;
  }

  async deleteUnwantedIncidentProcessAction(
    unwantedIncidentId: string,
    unwantedIncidentProcessId: string,
    unwantedIncidentProcessActionId: string,
  ): Promise<boolean> {
    if (!unwantedIncidentProcessActionId || !unwantedIncidentProcessId || !unwantedIncidentProcessActionId) {
      throw new Error(
        'unwanted incident id, unwanted incident process id and unwanted incident process action id is required',
      );
    }

    const response = await this.unwantedIncidentApi.unwantedIncidentProcessActionControllerDelete(
      unwantedIncidentId,
      unwantedIncidentProcessId,
      unwantedIncidentProcessActionId,
    );

    return response.data.deleted;
  }

  async readUnwantedIncidentProcessActions(
    unwantedIncidentId: string,
    unwantedIncidentProcessId: string,
    orderBy?: 'id' | 'name' | 'createdAt' | 'updatedAt',
    order?: 'ASC' | 'DESC',
    page?: number,
    limit?: number,
    search?: string,
  ): Promise<ResponseUnwantedIncidentProcessActionDtoPageResponse> {
    if (!unwantedIncidentId || !unwantedIncidentProcessId) {
      throw new Error('unwanted incident id and unwanted incident process id is required');
    }

    const response = await this.unwantedIncidentApi.unwantedIncidentProcessActionControllerReadCollection(
      unwantedIncidentId,
      unwantedIncidentProcessId,
      page,
      limit,
      order,
      orderBy,
      search,
    );

    return response.data;
  }

  async readUnwantedIncidentProjectsByGPS(
    lat: number,
    lng: number,
    distance = 500,
  ): Promise<ResponseNearbyUnwantedIncidentProjectDtoCollectionResponse> {
    const response = await this.unwantedIncidentApi.unwantedIncidentProjectControllerReadNearbyCollection(
      lng,
      lat,
      distance,
    );

    return response.data;
  }

  async readUnwantedIncidentProjectsForUser(
    page = 0,
    order: Order = Order.Asc,
    orderBy: UnwantedIncidentProjectPageOptionsOrderBy = UnwantedIncidentProjectPageOptionsOrderBy.Name,
    limit = 10,
    search = '',
  ): Promise<ResponseUnwantedIncidentProjectDtoPageResponse> {
    const response = await this.unwantedIncidentApi.unwantedIncidentProjectControllerReadCollection(
      page,
      limit,
      order,
      orderBy,
      search,
    );

    return response.data;
  }
}
