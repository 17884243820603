import { NgModule } from '@angular/core';

import { SpacePipe } from './space.pipe';

@NgModule({
  declarations: [SpacePipe],
  exports: [SpacePipe],
  imports: [],
  providers: [],
})
export class SpacePipeModule {}
