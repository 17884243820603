import { Injectable } from '@angular/core';
import moment from 'moment';
import { Observable } from 'rxjs';
import { HttpService } from '../../services/http/http.service';

import { createApiUrl, transformQueryParams } from '../../utils/functions';
import { CasesData } from './cases/cases.types';
import { CaseChartParams, CasesChartData } from './graphs/graphs.types';
import { NMRData } from './new-multi-residential/new-multi-residential.types';
import { ProjectCasesByDayData } from './project-cases-by-day/project-cases-by-day.types';
import { ProjectsTakeovers } from './project-takeovers/project-takeovers.types';
import { SalesScreenData } from './sales-screen/sales-screen.types';
import { SalesData } from './sales/sales.types';

interface ResolveParams {
  from: string;
  to: string;
  ProjectId?: number;
  projectId?: number;
}

@Injectable()
export class ReportingService extends HttpService<{ id: string }> {
  // Type is not used for anything.
  public route = 'report';

  salesScreen(): Promise<SalesScreenData> {
    return this.http.get<SalesScreenData>(`${this.apiUrl}/${this.route}/sales-screen`, this.options).toPromise();
  }

  sales(from: moment.Moment, to: moment.Moment, ProjectId?: number): Observable<SalesData> {
    if (!from.isValid() || !to.isValid()) {
      throw new Error('Need valid dates');
    }

    const params: ResolveParams = {
      from: from.toISOString(),
      to: to.toISOString(),
    };

    if (ProjectId) {
      params.ProjectId = ProjectId;
      params.projectId = ProjectId;
    }

    const url = `${this.apiUrl}/${this.route}/sales`;
    const options = Object.assign({}, this.options, { params });

    return this.http.get<SalesData>(url, options);
  }

  cases(project: number, category: number): Observable<CasesData> {
    const p = Number(project);
    const c = Number(category);

    const options = Object.assign({}, this.options, { params: {} });

    if (p) {
      options.params = {
        ...options.params,
        projectId: String(p),
      };
    }

    if (c) {
      options.params = {
        ...options.params,
        categoryId: String(c),
      };
    }

    return this.http.get<CasesData>(`${this.apiUrl}/${this.route}/cases`, options);
  }

  getCasesChartData(p: CaseChartParams): Observable<CasesChartData> {
    const params = p.caseParams;

    Object.assign(params, p.dateRange);

    const options = {
      headers: this.options.headers,
      withCredentials: this.options.withCredentials,
      params: transformQueryParams(params),
    };

    return this.http.get<CasesChartData>(`${this.apiUrl}/${this.route}/cases/charts`, options);
  }

  nmr(quarter: number, year: number): Observable<NMRData> {
    if (!quarter || !year) {
      throw new Error('Quarter and year are required');
    }

    const options = Object.assign({}, this.options, {
      params: {
        quarter,
        year,
      },
    });

    return this.http.get<NMRData>(`${this.apiUrl}/${this.route}/trude`, options);
  }

  getProjectCasesByDay(
    Project: number[],
    includeChecklistItems = false,
    includeArchivedCases = false,
  ): Observable<ProjectCasesByDayData> {
    const options = {
      ...this.options,
      params: {
        Project,
        includeChecklistItems,
        includeArchivedCases,
      },
    };

    return this.http.get<ProjectCasesByDayData>(`${this.apiUrl}/${this.route}/project-cases-by-day`, options);
  }

  getProjectTakeovers(Project: number[]): Observable<ProjectsTakeovers> {
    const options = {
      ...this.options,
      params: {
        Project,
      },
    };
    const url = createApiUrl('report', 'project-takeovers');

    return this.http.get<ProjectsTakeovers>(url, options);
  }
}
