import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'apex-unwanted-incident-confirmation',
  templateUrl: './unwanted-incident-confirmation.component.html',
})
export class UnwantedIncidentConfirmationComponent {
  projectId: number;
  objectId: number;

  constructor(private route: ActivatedRoute) {
    this.projectId = Number(route.snapshot.paramMap.get('ProjectId'));
    this.objectId = Number(route.snapshot.paramMap.get('ObjectId'));
  }

  get link(): string[] {
    if (this.projectId) {
      return ['/', 'project', String(this.projectId), 'unwanted-incident', 'new'];
    }

    if (this.objectId) {
      return ['/', 'object', String(this.objectId), 'unwanted-incident', 'new'];
    }

    return [];
  }
}
