import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormattedAddressComponent } from '../../../components/adresses/formatted-address/formatted-address.component';
import { AutocompleteModule } from '../../../components/autocomplete/autocomplete.module';
import { FileUploadModule } from '../../../components/file-upload/file-upload.module';
import { FileUsageViewerModule } from '../../../components/file-usage-viewer/file-usage-viewer.module';
import { ItemModule } from '../../../components/item/item.module';
import { PageInfoModule } from '../../../components/page-info/page-info.module';
import { TranslateModule } from '../../../components/translate/translate.module';
import { PostalDirectiveModule } from '../../../directives/postal/postal.directive.module';
import { FirstVisitationAddressPipe } from '../../../pipes/first-visitation-address/first-visitation-address.pipe';
import { ProjectService } from '../../project/project.service';
import { UnwantedIncidentService } from '../unwanted-incident.service';
import { UnwantedIncidentFormComponent } from './unwanted-incident-form.component';

@NgModule({
  declarations: [UnwantedIncidentFormComponent],
  imports: [
    CommonModule,
    FormsModule,
    MatFormFieldModule,
    TranslateModule,
    MatInputModule,
    AutocompleteModule,
    MatCheckboxModule,
    ItemModule,
    MatButtonModule,
    PageInfoModule,

    PostalDirectiveModule,
    FileUploadModule,
    FileUsageViewerModule,
    FirstVisitationAddressPipe,
    FormattedAddressComponent,
  ],
  providers: [ProjectService, UnwantedIncidentService],
})
export class UnwantedIncidentFormModule {}
