import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';

import { ReportingService } from '../reporting.service';
import { CasesData } from './cases.types';

export const CasesDataResolver: ResolveFn<CasesData> = (route: ActivatedRouteSnapshot) => {
  const service = inject(ReportingService);

  const p = Number(route.queryParamMap.get('project'));
  const c = Number(route.queryParamMap.get('category'));

  return service.cases(p, c);
};
