import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { PageNotFoundComponent } from 'projects/apex/src/app/components/page-not-found/page-not-found.component';

import { CasesComponent } from 'projects/apex/src/app/features/reporting/cases/cases.component';
import { CasesDataResolver } from 'projects/apex/src/app/features/reporting/cases/cases.resolver';
import { SalesScreenComponent } from 'projects/apex/src/app/features/reporting/sales-screen/sales-screen.component';
import { SalesScreenResolve } from 'projects/apex/src/app/features/reporting/sales-screen/sales-screen.resolve';

import { UnwantedIncidentProjectInfoResolver } from '../../../apex/src/app/features/project/project.resolver';
import { UnwantedIncidentConfirmationComponent } from '../../../apex/src/app/features/unwanted-incident/confirmation/unwanted-incident-confirmation.component';
import { UnwantedIncidentFormComponent } from '../../../apex/src/app/features/unwanted-incident/form/unwanted-incident-form.component';
import { NewUnwantedIncidentResolver } from '../../../apex/src/app/features/unwanted-incident/unwanted-incident.resolver';
import { HasAuthGuard } from './has-auth.guard';
import { SetupComponent } from './setup/setup.component';

const routes: Routes = [
  {
    path: 'sales-screen',
    component: SalesScreenComponent,
    canActivate: [HasAuthGuard],
    resolve: {
      salesScreen: SalesScreenResolve,
    },
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'cases',
    component: CasesComponent,
    canActivate: [HasAuthGuard],
    resolve: {
      casesData: CasesDataResolver,
    },
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'setup',
    component: SetupComponent,
  },
  {
    path: 'project/:ProjectId/unwanted-incident',
    children: [
      {
        path: 'new',
        resolve: {
          unwantedIncident: NewUnwantedIncidentResolver,
          projectInfo: UnwantedIncidentProjectInfoResolver,
        },
        component: UnwantedIncidentFormComponent,
      },
      {
        path: 'confirmation',
        component: UnwantedIncidentConfirmationComponent,
      },
    ],
  },
  {
    path: 'object/:ObjectId/unwanted-incident',
    children: [
      {
        path: 'new',
        resolve: {
          unwantedIncident: NewUnwantedIncidentResolver,
          projectInfo: UnwantedIncidentProjectInfoResolver,
        },
        component: UnwantedIncidentFormComponent,
      },
      {
        path: 'confirmation',
        component: UnwantedIncidentConfirmationComponent,
      },
    ],
  },
  {
    path: '**',
    component: PageNotFoundComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: 'reload',
    }),
  ],
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule {}
