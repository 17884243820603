<div class="ap">
  <p
    class="mat-headline-6"
    *ngIf="casesData?.meta?.name">
    <strong>{{ casesData?.meta?.name }}</strong>
    <ng-container *ngIf="casesData?.category?.name">
      <!-- @note Translations handled in caseCategories -->
      - {{ casesData?.category?.name | translate }}
    </ng-container>
  </p>
  <div class="df d-r t-r p-c amb mat-headline-6 jsb w">
    <div>
      <strong>{{ casesData?.period?.total }}</strong>
      <T str="last seven days"></T>
    </div>
    <div>
      <strong>{{ casesData?.totals?.total }}</strong>
      <T str="total"></T>
    </div>
    <div>
      <strong>{{ casesData?.totals?.open }}</strong>
      ({{ percent(casesData?.totals?.open, casesData?.totals?.total) }}%)
      <T str="open"></T>
    </div>
    <div>
      <strong>{{ casesData?.totals?.overdue }}</strong>
      ({{ percent(casesData?.totals?.overdue, casesData?.totals?.open) }}%)
      <T str="overdue"></T>
    </div>
    <div>
      <strong>{{ casesData?.totals?.closed }}</strong>
      ({{ percent(casesData?.totals?.closed, casesData?.totals?.total) }}%)
      <T str="closed"></T>
    </div>
  </div>

  <div class="scrollable-horizontal">
    <table
      mat-table
      [dataSource]="dataSource"
      matSort
      [matSortActive]="sortBy"
      [matSortDirection]="sortDirection"
      (matSortChange)="sorted($event)"
      class="mat-elevation-z2 w-100">
      <ng-container matColumnDef="customerName">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header>
          <T str="Company"></T>
        </th>
        <td
          mat-cell
          *matCellDef="let element">
          {{ element.customerName }}
        </td>
      </ng-container>

      <ng-container matColumnDef="open">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header>
          <T str="Open"></T>
        </th>
        <td
          mat-cell
          *matCellDef="let element">
          {{ element.open }} ({{ percent(element.open, element.total) }}%)
        </td>
      </ng-container>

      <ng-container matColumnDef="closed">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header>
          <T str="Closed"></T>
        </th>
        <td
          mat-cell
          *matCellDef="let element">
          {{ element.closed }} ({{ percent(element.closed, element.total) }}%)
        </td>
      </ng-container>

      <ng-container matColumnDef="overdue">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header>
          <T str="Overdue"></T>
        </th>
        <td
          mat-cell
          *matCellDef="let element">
          {{ element.overdue }} ({{ percent(element.overdue, element.open) }}%)
        </td>
      </ng-container>

      <ng-container matColumnDef="total">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header>
          <T str="Total"></T>
        </th>
        <td
          mat-cell
          *matCellDef="let element">
          {{ element.total }}
        </td>
      </ng-container>

      <tr
        mat-header-row
        *matHeaderRowDef="displayedColumns"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </div>
</div>
