import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { round } from 'lodash-es';
import { Subscription, interval } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { CasesData, CasesDataCustomer } from './cases.types';

@Component({
  selector: 'apex-reporting-cases',
  templateUrl: './cases.component.html',
})
export class CasesComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(MatSort) sort: MatSort;

  sortBy = 'open';
  sortDirection: 'asc' | 'desc' | '' = 'desc';

  casesData: CasesData;
  customerData: CasesDataCustomer[] = [];

  dataSource = new MatTableDataSource<CasesDataCustomer>([]);

  displayedColumns = ['customerName', 'open', 'closed', 'overdue', 'total'];

  private subscriptions: Subscription = new Subscription();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
  ) {}

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.route.data
        .pipe(
          map((data) => data.casesData),
          filter((cd) => !!cd),
        )
        .subscribe((casesData: CasesData) => {
          this.casesData = casesData;
          this.customerData = this.casesData?.customer;
          this.dataSource.data = this.customerData;
        }),
    );

    this.subscriptions.add(
      this.route.queryParams.subscribe((qs) => {
        if (qs.sortBy) {
          this.sortBy = qs.sortBy;
        }

        if (qs.sortDirection) {
          this.sortDirection = qs.sortDirection;
        }
      }),
    );

    this.subscriptions.add(
      interval(5 * 60 * 1000).subscribe(
        (_) =>
          void this.router.navigate([], {
            preserveFragment: true,
            queryParamsHandling: 'preserve',
          }),
      ),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  sorted(sort: Sort): void {
    void this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        sortBy: sort.active,
        sortDirection: sort.direction,
      },
      queryParamsHandling: 'merge',
    });
  }

  percent(value: number, total: number): number {
    const p = round(((value ?? 0) / (total ?? 1)) * 100, 1);

    return isFinite(p) ? p : 0;
  }

  percentClosed(): number {
    return this.percent(this.casesData?.totals?.closed, this.casesData?.totals?.total);
  }

  percentOpen(): number {
    return this.percent(this.casesData?.totals?.open, this.casesData?.totals?.total);
  }
}
