import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';

import { APEXCredentials } from './apex-credentials';

export const HasAuthGuard: CanActivateFn = () => {
  const credentials: APEXCredentials = new APEXCredentials();
  const router = inject(Router);

  if (!credentials.valid()) {
    void router.navigate(['setup'], { queryParams: { error: 'missing-credentials' } });

    return false;
  }

  return true;
};
