<div
  class="content df c"
  *ngIf="salesScreen">
  <div class="header df r jsb ac">
    <div class="logo">
      <img [src]="salesScreen.logo" />
    </div>
    <div
      class="latest-sale"
      *ngIf="latestSale">
      <p class="mat-body">Sist solgt:</p>
      <p class="mat-headline-5">
        {{ latestSale.saleDate | date: 'shortDate' }}
        {{ latestSale.projectName }}:
        {{ latestSale.apartmentName }}
        for
        {{ latestSale.salePrice | space }}
      </p>
    </div>
  </div>

  <div class="scrollable-horizontal">
    <table class="w-100 mat-table mat-elevation-z1">
      <thead>
        <tr class="mat-header-row">
          <th class="mat-header-cell">Prosjekt</th>
          <th class="mat-header-cell">Salgsstart</th>
          <th class="mat-header-cell">Antall</th>
          <th class="mat-header-cell">Solgte</th>
          <th class="mat-header-cell">Ledige</th>
          <th class="mat-header-cell">Overleverte</th>
          <th class="mat-header-cell">Total Omsetning</th>
          <th class="mat-header-cell">Solgt Omsetning</th>
          <th class="mat-header-cell">solgt oms.</th>
          <th class="mat-header-cell">oms. fin.</th>
          <th
            class="mat-header-cell past"
            *ngFor="let i of [11, 10, 9, 8, 7, 6, 5, 4, 3, 2, 1, 0]">
            {{ monthNameInPast(i) }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          class="mat-row"
          *ngFor="let project of salesScreen?.projects">
          <td class="mat-cell">
            {{ project.name }}
          </td>
          <td class="mat-cell">
            <ng-container *ngIf="project.salesStartDate">
              {{ project.salesStartDate | date: 'shortDate' }}
            </ng-container>
          </td>
          <td class="mat-cell">
            <ng-container *ngIf="project.apartmentsCount">
              {{ project.apartmentsCount }}
            </ng-container>
          </td>
          <td class="mat-cell">
            <ng-container *ngIf="project.apartmentsSoldCount">
              {{ project.apartmentsSoldCount }}
            </ng-container>
          </td>
          <td class="mat-cell">
            <ng-container *ngIf="project.apartmentsUnsoldCount">
              {{ project.apartmentsUnsoldCount }}
            </ng-container>
          </td>
          <td class="mat-cell">
            <ng-container *ngIf="project.apartmentsTakeovers">
              {{ project.apartmentsTakeovers }}

              <span class="gray-color"> ({{ percent(project.apartmentsTakeovers, project.apartmentsCount) }}%) </span>
            </ng-container>
          </td>
          <td class="mat-cell">
            <ng-container *ngIf="project.apartmentsBudgetPrice">
              {{ project.apartmentsBudgetPrice | space }}
            </ng-container>
          </td>
          <td class="mat-cell">
            <ng-container *ngIf="project.apartmentsSoldPrice">
              {{ project.apartmentsSoldPrice | space }}
            </ng-container>
          </td>
          <td class="mat-cell">
            <ng-container *ngIf="project.revenueSoldPercent"> {{ project.revenueSoldPercent }}% </ng-container>
          </td>
          <td class="mat-cell">
            <ng-container *ngIf="project.revenueFinancialProofPercent">
              {{ project.revenueFinancialProofPercent }}%
            </ng-container>
          </td>
          <td
            class="mat-cell"
            *ngFor="let i of [11, 10, 9, 8, 7, 6, 5, 4, 3, 2, 1, 0]">
            {{ project['salesPast' + i] }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <footer class="footer df r jsa ac">
    <div class="past mat-elevation-z1 w-40">
      <h2 class="mat-body-strong">{{ currentYear - 1 }}</h2>
      <p>
        Antall solgt / overlevert:
        {{ salesScreen.yearly.salesPast.count }}
        /
        {{ salesScreen.yearly.takeoversPast.count }}
      </p>
      <p>
        Omsetning solgt / overlevert:
        {{ salesScreen.yearly.salesPast.sum | space }}
        /
        {{ salesScreen.yearly.takeoversPast.sum | space }}
      </p>
    </div>
    <div class="current mat-elevation-z1 w-40">
      <h2 class="mat-body-strong">{{ currentYear }}</h2>
      <p>
        Antall solgt / overlevert:
        {{ salesScreen.yearly.salesCurrent.count }}
        /
        {{ salesScreen.yearly.takeoversCurrent.count }}
      </p>
      <p>
        Omsetning solgt / overlevert:
        {{ salesScreen.yearly.salesCurrent.sum | space }}
        /
        {{ salesScreen.yearly.takeoversCurrent.sum | space }}
      </p>
    </div>
  </footer>
</div>
