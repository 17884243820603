<apex-page-info>
  <T
    title
    str="Confirmation"
    context="unwanted-incident"
    _context="unwanted-incident">
  </T>
</apex-page-info>

<div class="df c ac jc am ap">
  <p>
    <T
      str="Thank you for reporting this unwanted incident."
      context="unwanted-incident"
      _context="unwanted-incident"></T>
  </p>
  <p>
    <T
      str="If you want to create another one"
      context="unwanted-incident"
      _context="unwanted-incident"></T>
    <span class="amls">
      <a
        mat-raised-button
        color="primary"
        [routerLink]="link">
        <T
          str="Click here"
          context="unwanted-incident"
          _context="unwanted-incident"></T>
      </a>
    </span>
  </p>
</div>
