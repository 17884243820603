<apex-page-info>
  <T
    title
    str="Unwanted Incident Form"
    context="unwanted-incident"
    _context="unwanted-incident">
  </T>
</apex-page-info>

<form
  class="am mat-elevation-z2"
  #form="ngForm"
  (ngSubmit)="sendIn()">
  <div class="display-grid-cards auto-fit am apt">
    <mat-form-field>
      <mat-label>
        <T str="Name"></T>
      </mat-label>
      <input
        matInput
        required
        name="name"
        type="text"
        [(ngModel)]="unwantedIncident.reporterName" />
    </mat-form-field>

    <mat-form-field>
      <mat-label>
        <T str="Email"></T>
      </mat-label>
      <input
        matInput
        required
        name="email"
        type="email"
        [(ngModel)]="unwantedIncident.reporterEmail" />
    </mat-form-field>

    <mat-form-field>
      <mat-label>
        <T str="Phone"></T>
      </mat-label>
      <input
        matInput
        required
        name="phone"
        [(ngModel)]="unwantedIncident.reporterMobile" />
    </mat-form-field>

    <mat-form-field>
      <mat-label>
        <T str="Company"></T>
      </mat-label>
      <input
        matInput
        required
        name="company"
        type="text"
        [(ngModel)]="unwantedIncident.reporterCompany" />
    </mat-form-field>
  </div>

  <div class="display-grid-cards am">
    <apex-title-item>
      <T
        title
        str="Project"></T>

      {{ unwantedIncidentProjectInfo?.name }}
    </apex-title-item>

    <ng-container *ngIf="unwantedIncidentProjectInfo.addresses | firstVisitationAddress as address">
      <apex-title-item>
        <T
          title
          str="Address"></T>

        <apex-formatted-address [address]="address"></apex-formatted-address>
      </apex-title-item>

      <apex-title-item *ngIf="!!address.postal">
        <T
          title
          str="Zip"></T>

        {{ address.postal }}
      </apex-title-item>
    </ng-container>
  </div>

  <div class="df afs">
    <apex-title-item class="am f2">
      <T
        title
        str="Type (optional)"
        context="unwanted-incident"
        _context="unwanted-incident">
      </T>

      <div class="display-grid-cards card-width-200 amts">
        <mat-checkbox
          name="accident"
          [(ngModel)]="accidentChecked">
          <T
            str="Accident"
            context="unwanted-incident"
            _context="unwanted-incident">
          </T>
        </mat-checkbox>

        <mat-checkbox
          name="personInjury"
          [(ngModel)]="personInjuryChecked">
          <T
            str="Person injury"
            context="unwanted-incident"
            _context="unwanted-incident">
          </T>
        </mat-checkbox>

        <mat-checkbox
          name="materialDamage"
          [(ngModel)]="materialDamageChecked">
          <T
            str="Material damage"
            context="unwanted-incident"
            _context="unwanted-incident">
          </T>
        </mat-checkbox>

        <mat-checkbox
          name="nearAccident"
          [(ngModel)]="nearAccidentChecked">
          <T
            str="Near accident"
            context="unwanted-incident"
            _context="unwanted-incident">
          </T>
        </mat-checkbox>

        <mat-checkbox
          name="observation"
          [(ngModel)]="observationChecked">
          <T
            str="Observation"
            context="unwanted-incident"
            _context="unwanted-incident">
          </T>
        </mat-checkbox>
      </div>
    </apex-title-item>
    <ng-container *ngIf="unwantedIncidentProjectInfo?.floorPlans?.length > 0">
      <div class="df c afe jfe f1">
        <button
          mat-raised-button
          class="am"
          type="button"
          (click)="openMarkingDialog()">
          <T
            str="Mark on floor plan"
            _context="action"
            context="action"></T>
        </button>
        <T
          class="amr"
          str="{count, plural, one {One marking} other {# markings}}."
          [vars]="{ count: unwantedIncident?.Markings?.length }"></T>
      </div>
    </ng-container>
  </div>

  <div class="df c amt amh">
    <mat-form-field>
      <mat-label>
        <T
          str="Incident"
          context="unwanted-incident"
          _context="unwanted-incident">
        </T>
      </mat-label>
      <textarea
        matInput
        name="incident"
        cdkTextareaAutosize
        cdkAutosizeMinRows="5"
        cdkAutosizeMaxRows="10"
        [(ngModel)]="unwantedIncident.incident"
        required>
      </textarea>
    </mat-form-field>

    <mat-form-field>
      <mat-label>
        <T
          str="Immediate action"
          context="unwanted-incident"
          _context="unwanted-incident">
        </T>
      </mat-label>
      <textarea
        matInput
        name="immediateAction"
        cdkTextareaAutosize
        cdkAutosizeMinRows="5"
        cdkAutosizeMaxRows="10"
        [(ngModel)]="unwantedIncident.immediateAction"
        required>
      </textarea>
    </mat-form-field>

    <mat-form-field>
      <mat-label>
        <T
          str="Consequence"
          context="unwanted-incident"
          _context="unwanted-incident">
        </T>
      </mat-label>
      <textarea
        matInput
        name="consequence"
        cdkTextareaAutosize
        cdkAutosizeMinRows="5"
        cdkAutosizeMaxRows="10"
        [(ngModel)]="unwantedIncident.consequence">
      </textarea>
    </mat-form-field>
  </div>

  <apex-file-upload
    class="db amh amb"
    [expanded]="true"
    [allowedMimeTypes]="allowedMimeTypes"
    name="files"
    [(ngModel)]="unwantedIncidentFileKeys">
  </apex-file-upload>

  <div class="df jfe apb amr">
    <button
      mat-raised-button
      color="primary"
      type="submit"
      [disabled]="!form.valid">
      <T
        str="Send in"
        context="unwanted-incident"
        _context="unwanted-incident"></T>
    </button>
  </div>
</form>
