<div mat-dialog-title>
  <div class="df f1 jsb ac">
    <div class="df c ac jc">
      <h1>
        <T
          class="mat-headline-5"
          str="Marking unwanted incident on floor plans"></T>
      </h1>
    </div>
  </div>
</div>
<div
  mat-dialog-content
  class="df c gap f1">
  <div class="f1">
    <ng-container *ngIf="selectedFileUsage">
      <apex-image-viewer
        #imageViewer
        class="f1 min-height-400"
        [file]="selectedFileUsage.File"
        [markings]="markings | filterByKey: 'FileUsageId' : selectedFileUsage.id"
        (markingClicked)="removeMarking($event)"
        (markingCreate)="createMarking($event)"
        (markingChange)="updateMarking($event)"></apex-image-viewer>
    </ng-container>
  </div>
  <mat-divider></mat-divider>
  <div class="df gap jc ac">
    <ng-container *ngFor="let floorPlan of floorPlans">
      <div
        (click)="selectedFileUsage = floorPlan"
        class="clickable">
        <apex-file-preview
          [file]="floorPlan.File"
          [size]="{ width: 50, height: 50 }">
        </apex-file-preview>
      </div>
    </ng-container>
  </div>
</div>
<div mat-dialog-actions>
  <div class="df f1 jfe gap">
    <button
      mat-flat-button
      (click)="cancel()">
      <T
        str="Cancel"
        _context="action"
        context="action"></T>
    </button>
    <button
      mat-raised-button
      color="primary"
      (click)="save()">
      <T
        str="Save"
        _context="action"
        context="action"></T>
    </button>
  </div>
</div>
