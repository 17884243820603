import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterByKey',
  standalone: true,
})
export class FilterByKeyPipe implements PipeTransform {
  transform<Type extends object, Key extends keyof Type>(values: Type[], key: Key, value: Type[Key]): Type[] {
    return values.filter((v) => v[key] === value);
  }
}
