import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { APEXCredentials } from './apex-credentials';

const credentials = new APEXCredentials();

@Injectable()
export class SalesScreenInterceptor implements HttpInterceptor {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!req.url.includes('/report/')) {
      return next.handle(req);
    }

    const url = req.url.replace('/report/', '/public/report/');

    const cloneReq = req.clone({
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        APEXCustomer: credentials.APEXCustomer,
        APEXKey: credentials.APEXKey,
        APEXSecret: credentials.APEXSecret,
      }),
      url,
    });

    return next.handle(cloneReq);
  }
}
