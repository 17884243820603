import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';

import { TranslateModule } from '../../../components/translate/translate.module';

import { CasesComponent } from './cases.component';

@NgModule({
  declarations: [CasesComponent],
  exports: [CasesComponent],
  imports: [CommonModule, TranslateModule, MatTableModule, MatSortModule],
  providers: [],
})
export class CasesModule {}
