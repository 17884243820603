import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { pick } from 'lodash-es';
import moment from 'moment';
import { GeometryType, UnwantedIncidentMarkingDto } from '../../../../../../../generated/apex-rest';
import { FilePreviewModule } from '../../../components/file-preview/file-preview.module';
import { ImageViewerComponent } from '../../../components/image-viewer/image-viewer.component';
import { ImageViewerModule } from '../../../components/image-viewer/image-viewer.module';
import { TranslateModule } from '../../../components/translate/translate.module';
import { FileUsage } from '../../../models/file-usage';
import { Marking, MarkingModelType } from '../../../models/marking';
import { FilterByKeyPipe } from '../../../pipes/filter-by-key/filter-by-key.pipe';
import {
  UnwantedIncidentMarkingDialogData,
  UnwantedIncidentMarkingDialogReturnData,
} from './unwanted-incident-marking-dialog-data.type';

@Component({
  selector: 'apex-unwanted-incident-marking-dialog',
  templateUrl: './unwanted-incident-marking-dialog.component.html',
  styleUrls: ['./unwanted-incident-marking-dialog.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    TranslateModule,
    MatDividerModule,
    ImageViewerModule,
    FilterByKeyPipe,
    FilePreviewModule,
    MatIconModule,
  ],
})
export class UnwantedIncidentMarkingDialogComponent implements OnInit {
  markings: Marking[] = [];

  floorPlans: FileUsage[];

  selectedFileUsage: FileUsage;

  @ViewChild('imageViewer') imageViewer: ImageViewerComponent;

  constructor(
    public dialogRef: MatDialogRef<UnwantedIncidentMarkingDialogComponent, UnwantedIncidentMarkingDialogReturnData>,
    @Inject(MAT_DIALOG_DATA) public data: UnwantedIncidentMarkingDialogData,
  ) {}

  ngOnInit(): void {
    this.dialogRef?.addPanelClass(['apex-fullscreen-dialog', 'tablet', 'phone']);

    this.floorPlans =
      this.data.floorPlans.map((floorPlan) => ({
        ...floorPlan,
        User: undefined,
        File: {
          ...floorPlan.File,
          Creator: undefined,
          createdAt: new Date(floorPlan.createdAt),
          updatedAt: new Date(floorPlan.updatedAt),
          deletedAt: undefined,
        },
        createdAt: new Date(floorPlan.createdAt),
        updatedAt: new Date(floorPlan.updatedAt),
        deletedAt: undefined,
      })) ?? [];

    if (this.floorPlans.length) {
      this.selectedFileUsage = this.floorPlans[0];
    }

    if (this.data.markings) {
      this.markings = this.data.markings.map((marking, index) =>
        this.mapUnwantedIncidentMarkingToMarking(marking, index),
      );
    }
  }

  removeMarking(marking: Marking): void {
    this.markings = this.markings.filter((m) => m.id !== marking.id);
  }

  updateMarking(marking: Marking): void {
    const markingIndex = this.markings.findIndex((m) => m.id === marking.id);

    if (markingIndex === -1) {
      return;
    }

    this.markings[markingIndex] = marking;
  }

  createMarking(marking: Marking): void {
    marking.FileUsageId = this.selectedFileUsage.id;
    marking.id = -moment().unix() + Math.floor(Math.random() * 3000);

    this.markings = [...this.markings, marking];
  }

  save(): void {
    const unwantedIncidentMarkings = this.markings.map((marking) =>
      this.mapMarkingToUnwantedIncidentMarkingDto(marking),
    );

    this.dialogRef.close({ markings: unwantedIncidentMarkings, saveMarkings: true });
  }

  protected cancel(): void {
    this.dialogRef.close({ markings: [], saveMarkings: false });
  }

  private mapMarkingToUnwantedIncidentMarkingDto(marking: Marking): UnwantedIncidentMarkingDto {
    return {
      ...pick(marking, ['description', 'icon', 'FileUsageId', 'correct', 'data']),
      geometry: {
        ...marking.geometry,
        type: GeometryType.Point,
      },
    };
  }

  private mapUnwantedIncidentMarkingToMarking(
    unwantedIncidentMarking: UnwantedIncidentMarkingDto,
    index: number,
  ): Marking | undefined {
    const coordinates = unwantedIncidentMarking.geometry.coordinates;

    const x = coordinates[0];
    const y = coordinates[1];

    if (typeof x !== 'number' || typeof y !== 'number') {
      return undefined;
    }

    return {
      id: index,
      modelId: 0,
      CreatorId: 0,
      description: unwantedIncidentMarking.description ?? '',
      icon: unwantedIncidentMarking.icon ?? '',
      correct: unwantedIncidentMarking.correct ?? false,
      model: MarkingModelType.Case,
      ...pick(unwantedIncidentMarking, ['description', 'icon', 'FileUsageId', 'correct', 'data']),
      geometry: {
        coordinates: [x, y],
        type: String(GeometryType.Point),
      },
      data: unwantedIncidentMarking.data ?? {},
    };
  }
}
