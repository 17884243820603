export class APEXCredentials {
  get APEXCustomer(): string {
    return localStorage.getItem('APEXCustomer');
  }

  set APEXCustomer(str: string) {
    localStorage.setItem('APEXCustomer', str);
  }

  get APEXKey(): string {
    return localStorage.getItem('APEXKey');
  }

  set APEXKey(str: string) {
    localStorage.setItem('APEXKey', str);
  }

  get APEXSecret(): string {
    return localStorage.getItem('APEXSecret');
  }

  set APEXSecret(str: string) {
    localStorage.setItem('APEXSecret', str);
  }

  headers(): Record<string, string> {
    return {
      APEXCustomer: this.APEXCustomer,
      APEXKey: this.APEXKey,
      APEXSecret: this.APEXSecret,
    };
  }

  valid(): boolean {
    return (
      this.APEXCustomer &&
      this.APEXCustomer?.length > 0 &&
      this.APEXKey &&
      this.APEXKey?.length > 0 &&
      this.APEXSecret &&
      this.APEXSecret?.length > 0
    );
  }
}
