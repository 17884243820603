import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';
import { PageInfoModule } from '../../../components/page-info/page-info.module';
import { TranslateModule } from '../../../components/translate/translate.module';
import { UnwantedIncidentConfirmationComponent } from './unwanted-incident-confirmation.component';

@NgModule({
  declarations: [UnwantedIncidentConfirmationComponent],
  imports: [CommonModule, PageInfoModule, TranslateModule, MatButtonModule, RouterModule],
})
export class UnwantedIncidentConfirmationModule {}
