import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Params, ResolveFn } from '@angular/router';
import { Project, ProjectAddonCount, ProjectSalesSummaryData } from 'projects/apex/src/app/models/project';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import {
  ResponseObjectUnwantedIncidentInfoDto,
  ResponseProjectUnwantedIncidentInfoDto,
} from '../../../../../../generated/apex-rest';
import { t } from '../../components/translate/translate.function';
import { Field } from '../../models/field';
import { ProjectField } from '../../models/project-field';
import { CollectionResponse } from '../../utils/types';
import { ProjectService } from './project.service';

export const ProjectAddonsCountResolver: ResolveFn<ProjectAddonCount[]> = () => {
  const service = inject(ProjectService);

  return service.getProjectAddonsCount();
};

export const ProjectsUserCanEditAddonOnGetResolver: ResolveFn<Project[]> = () => {
  const service = inject(ProjectService);

  return service.getProjectsUserCanEditAddonOn().pipe(catchError(() => of([])));
};

export const ProjectsUserIsAddonContractorOnGetResolver: ResolveFn<Project[]> = () => {
  const service = inject(ProjectService);

  return service.getProjectUserIsAddonContractorOn().pipe(catchError(() => of([])));
};

export const ProjectsResolver: ResolveFn<CollectionResponse<Project>> = (route: ActivatedRouteSnapshot) => {
  const service = inject(ProjectService);

  const { projectSearch, limit, page, orderBy, order } = route.queryParams;

  const params: Params = {
    ...route.queryParams,
    search: projectSearch ?? '',
    limit: limit ?? '25',
    page: page ?? '0',
    orderBy: orderBy ?? 'name',
    order: order ?? 'ASC',
  };

  return service.query({ params });
};

export const ProjectResolver: ResolveFn<Project> = (route: ActivatedRouteSnapshot) => {
  const service = inject(ProjectService);

  const projectId = Number(route.paramMap.get('ProjectId'));

  return service.get(projectId).pipe(map((res) => res.Entity));
};

export const ProjectFieldsResolver: ResolveFn<ProjectField[]> = (
  route: ActivatedRouteSnapshot,
): Observable<ProjectField[]> => {
  const service = inject(ProjectService);

  const projectId = Number(route.paramMap.get('ProjectId'));

  return service.getFields(projectId);
};

export const ProjectCustomerFieldsResolver: ResolveFn<Field[]> = (route: ActivatedRouteSnapshot) => {
  const service = inject(ProjectService);

  const projectId = Number(route.paramMap.get('ProjectId'));

  return service.getCustomerFields(projectId);
};

export const ProjectSalesSummaryResolver: ResolveFn<ProjectSalesSummaryData> = (route: ActivatedRouteSnapshot) => {
  const service = inject(ProjectService);

  const projectId = Number(route.paramMap.get('ProjectId'));

  return service.getProjectSalesSummary(projectId);
};

export const NewProjectResolver: ResolveFn<Project> = () => {
  const project = new Project({
    name: t('New Project'),
    status: 1,
    inSalesReporting: 1,
    allowRequests: true,
    shareEmailRepliesWithAllInvolved: false,
  });

  return of(project);
};

export const UnwantedIncidentProjectInfoResolver: ResolveFn<
  Promise<ResponseProjectUnwantedIncidentInfoDto | ResponseObjectUnwantedIncidentInfoDto>
> = (
  route: ActivatedRouteSnapshot,
): Promise<ResponseProjectUnwantedIncidentInfoDto | ResponseObjectUnwantedIncidentInfoDto> => {
  const service = inject(ProjectService);

  const projectId = Number(route.paramMap.get('ProjectId'));
  const objectId = Number(route.paramMap.get('ObjectId'));

  if (projectId) {
    return service.getUnwantedIncidentProjectInfo(projectId);
  }

  if (objectId) {
    return service.getUnwantedIncidentObjectInfo(objectId);
  }

  throw new Error('ProjectId or ObjectId is required');
};
