import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BoxModule } from '../box/box.module';
import { DragModule } from '../drag/drag.module';
import { FileService } from '../file-usage/file.service';
import { TranslateModule } from '../translate/translate.module';
import { FileUploadComponent } from './file-upload.component';

@NgModule({
  declarations: [FileUploadComponent],
  imports: [CommonModule, DragModule, MatIconModule, TranslateModule, BoxModule, MatButtonModule, MatTooltipModule],
  providers: [FileService],
  exports: [FileUploadComponent],
})
export class FileUploadModule {}
