import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatTableModule } from '@angular/material/table';

import { TranslateModule } from '../../../components/translate/translate.module';
import { SpacePipeModule } from '../../../pipes/space/space.pipe.module';

import { SalesScreenComponent } from './sales-screen.component';

@NgModule({
  declarations: [SalesScreenComponent],
  exports: [SalesScreenComponent],
  imports: [CommonModule, TranslateModule, SpacePipeModule, MatTableModule],
  providers: [],
})
export class SalesScreenModule {}
