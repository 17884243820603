import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { APEXCredentials } from '../apex-credentials';

@Component({
  selector: 'reporting-setup',
  templateUrl: './setup.component.html',
})
export class SetupComponent implements OnInit, OnDestroy {
  credentials: APEXCredentials;

  private qpSubscription: Subscription;

  constructor(
    private router: Router,
    private ar: ActivatedRoute,
  ) {}

  onSubmit(): void {
    void this.router.navigate(['sales-screen'], {});
  }

  ngOnInit(): void {
    this.credentials = new APEXCredentials();

    this.qpSubscription = this.ar.queryParamMap.subscribe((params) => {
      const customer = params.get('customer');
      const key = params.get('key');
      const secret = params.get('secret');

      if (customer) {
        this.credentials.APEXCustomer = customer;
      }

      if (key) {
        this.credentials.APEXKey = key;
      }

      if (secret) {
        this.credentials.APEXSecret = secret;
      }

      if (customer && key && secret) {
        this.onSubmit();
      }
    });
  }

  ngOnDestroy(): void {
    this.qpSubscription?.unsubscribe();
  }
}
