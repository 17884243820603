import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { orderBy, round } from 'lodash-es';
import { Subscription, interval } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { SalesScreenData, SalesScreenSale } from './sales-screen.types';

import moment from 'moment';

@Component({
  selector: 'apex-sales-screen',
  templateUrl: './sales-screen.component.html',
})
export class SalesScreenComponent implements OnInit, OnDestroy {
  salesScreen: SalesScreenData;
  latestSale: SalesScreenSale;

  currentYear: number = new Date().getFullYear();

  displayedColumns = [
    'name',
    'salesStartDate',
    'apartmentsCount',
    'apartmentsSoldCount',
    'apartmentsUnsoldCount',
    'apartmentsTakeovers',
    'apartmentsBudgetPrice',
    'apartmentsSoldPrice',
    'revenueSoldPercent',
    'revenueFinancialProofPercent',
    'salesPast11',
    'salesPast10',
    'salesPast9',
    'salesPast8',
    'salesPast7',
    'salesPast5',
    'salesPast4',
    'salesPast3',
    'salesPast2',
    'salesPast1',
    'salesPast0',
  ];

  private subscriptions: Subscription = new Subscription();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.subscriptions.add(
      this.route.data
        .pipe(
          map((data) => data.salesScreen),
          filter((ssd) => !!ssd),
        )
        .subscribe((ssd: SalesScreenData) => {
          this.salesScreen = ssd;
          this.latestSale = orderBy(ssd.latestSales ?? [], ['saleDate'], ['asc']).pop();
        }),
    );

    // Every 5 minutes
    this.subscriptions.add(
      interval(5 * 60 * 1000).subscribe((_) => {
        void this.router.navigate([], {
          queryParamsHandling: 'preserve',
          preserveFragment: true,
        });
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public monthNameInPast(dist: number): string {
    const m = moment().subtract(dist, 'month');
    const mon = m.format('MMMM');
    const yea = m.format('YY');

    return `${mon.slice(0, 3)} ${yea}`;
  }

  public percent(count: number, max: number): number {
    return round(((count ? count : 0) / (max ? max : 1)) * 100, 0);
  }
}
