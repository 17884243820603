import { Component, EventEmitter, forwardRef, Input, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { noop } from 'lodash-es';
import { firstValueFrom } from 'rxjs';
import {
  CreateFileSigningPolicyDto,
  MimeType,
  ResponseFileSigningPolicyDto,
} from '../../../../../../generated/apex-rest';
import { snack, snackErr } from '../../modules/snack.module';
import { getFileTypesForFileList } from '../../utils/file';
import { BoxComponent } from '../box/box.component';
import { SignedData } from '../file-usage/file-usage.types';
import { FileService } from '../file-usage/file.service';
import { FileIcons } from '../file-viewer/file-viewer-utils';
import { t } from '../translate/translate.function';
import { ViewFile } from './file-upload.type';

@Component({
  selector: 'apex-file-upload',
  templateUrl: './file-upload.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FileUploadComponent),
      multi: true,
    },
  ],
})
export class FileUploadComponent implements ControlValueAccessor {
  @ViewChild('box') box: BoxComponent;

  @Input() allowedMimeTypes: MimeType[] = Object.values(MimeType);

  @Input() title: string = t('Upload files');
  @Input() description: string = t('Drop your files below to upload them');
  @Input() expanded = false;
  @Input() icon = '';

  fileKeys: string[] = [];
  files: File[] = [];
  viewFiles: ViewFile[] = [];

  @Output() viewFilesChange = new EventEmitter<ViewFile[]>();

  dragOver = false;
  validFiles = false;
  checkTypes = true;

  loading = false;
  disabled = false;

  onChange: (fileKey: string[]) => void = noop;
  onTouched: () => void = noop;

  constructor(private fileService: FileService) {}

  writeValue(value: string | string[]): void {
    if (!value) {
      return;
    }

    if (Array.isArray(value)) {
      this.fileKeys = value;
    } else {
      this.fileKeys = [value];
    }
  }

  registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  removeFile(viewFile: ViewFile): void {
    const fileKeyIdx = this.fileKeys.indexOf(viewFile.key);
    const viewFileIdx = this.viewFiles.indexOf(viewFile);

    if (fileKeyIdx !== -1) {
      this.fileKeys.splice(fileKeyIdx, 1);
    }

    if (viewFileIdx !== -1) {
      this.viewFiles.splice(viewFileIdx, 1);
    }

    this.viewFilesChange.emit([...this.viewFiles]);
    this.onChange(this.fileKeys);
  }

  async uploadFilesFromClick(event: Event): Promise<void> {
    const fileList = (event.target as HTMLInputElement).files;

    if (!fileList?.length) {
      this.checkTypes = true;

      return;
    }

    if (!this.hasValidTypes(fileList)) {
      snack(
        t('{count, plural, one {Your file is} other {One or more of your files are}} the wrong type.', {
          count: fileList.length,
        }),
      );

      this.checkTypes = true;

      return;
    }

    this.checkTypes = true;

    await this.processFiles(fileList);
  }

  async uploadFilesFromDrop(event: DragEvent): Promise<void> {
    event.preventDefault();

    const fileList = event.dataTransfer.files;

    if (!fileList) {
      this.dragDone();

      return;
    }

    if (!this.hasValidTypes(fileList)) {
      snack(
        t('{count, plural, one {Your file is} other {One or more of your files are}} the wrong type.', {
          count: fileList.length,
        }),
      );

      this.dragDone();

      return;
    }

    this.dragDone();

    await this.processFiles(fileList);
  }

  onDragOver(event: DragEvent): void {
    event.preventDefault();

    const items = event.dataTransfer.items;

    if (!items.length) {
      return;
    }

    this.dragOver = true;

    if (!this.hasValidTypes(items)) {
      return;
    }
  }

  onDragLeave(event: DragEvent): void {
    event.preventDefault();

    this.dragDone();
  }

  private dragDone(): void {
    this.checkTypes = true;
    this.dragOver = false;
  }

  private async processFiles(fileList: FileList): Promise<void> {
    if (!fileList?.length) {
      return;
    }

    this.loading = true;

    for (let i = 0, filesLength = fileList.length; i < filesLength; i++) {
      const file = fileList[i];

      const fileToSave: CreateFileSigningPolicyDto = {
        name: file.name,
        size: file.size,
        type: file.type,
      };

      const signedFile = await this.fileService.restSign(fileToSave);
      const signedFileMapped: SignedData = {
        ...signedFile,
        acl: signedFile.acl,
        awsaccesskeyid: signedFile.awsAccessKeyId,
      };

      const isUploadedFile = await firstValueFrom(this.fileService.upload(signedFileMapped, file));

      if (isUploadedFile) {
        this.fileKeys.push(signedFile.key);
        this.files.push(file);

        this.onChange(this.fileKeys);

        this.readFile(file, signedFile);
      } else {
        const error = new Error(`Error uploading file ${file.name}`);

        snackErr(t('Error uploading file'), error);
      }
    }

    snack(t('Files uploaded'));
    this.loading = false;
  }

  private readFile(file: File, signedFile: ResponseFileSigningPolicyDto): void {
    const fileReader = new FileReader();

    fileReader.readAsDataURL(file);

    fileReader.onload = (event): void => {
      let icon = FileIcons.DefaultIcon;

      if (file.type === MimeType.ApplicationPdf) {
        icon = FileIcons.PdfIcon;
      } else if (file.type.startsWith('video/')) {
        icon = FileIcons.VideoIcon;
      } else if (file.type.startsWith('image/')) {
        icon = null;
      }

      this.viewFiles.push({
        file,
        src: event.target.result,
        icon,
        key: signedFile.key,
      });

      this.viewFilesChange.emit(this.viewFiles);
    };

    fileReader.onerror = (): void => snackErr(t('Unable to read file'), fileReader.error);
  }

  private isTypesValid(types: string[]): boolean {
    if (!types?.length) {
      return false;
    }

    return types.every((type) => this.allowedMimeTypes.some((mimeType) => mimeType === type));
  }

  private hasValidTypes(list: FileList | DataTransferItemList): boolean {
    if (!this.checkTypes) {
      return this.validFiles;
    }

    const types = getFileTypesForFileList(list);
    const isValid = this.isTypesValid(types);

    this.validFiles = isValid;
    this.checkTypes = false;

    return isValid;
  }
}
