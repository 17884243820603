import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MAT_MOMENT_DATE_FORMATS } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTableModule } from '@angular/material/table';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';
import { CasesModule } from 'projects/apex/src/app/features/reporting/cases/cases.module';
import { ReportingService } from 'projects/apex/src/app/features/reporting/reporting.service';
import { SalesScreenModule } from 'projects/apex/src/app/features/reporting/sales-screen/sales-screen.module';
import { locale } from 'projects/apex/src/app/utils/functions';
import { FileUsageViewerModule } from '../../../apex/src/app/components/file-usage-viewer/file-usage-viewer.module';
import { FileUsageModule } from '../../../apex/src/app/components/file-usage/file-usage.module';
import { TranslateModule } from '../../../apex/src/app/components/translate/translate.module';
import { UnwantedIncidentConfirmationModule } from '../../../apex/src/app/features/unwanted-incident/confirmation/unwanted-incident-confirmation.module';
import { UnwantedIncidentFormModule } from '../../../apex/src/app/features/unwanted-incident/form/unwanted-incident-form.module';
import { RequestStatusInterceptor } from '../../../apex/src/app/interceptors/status/request-status.interceptor';
import StatusServiceModule from '../../../apex/src/app/interceptors/status/status.service.module';
import { SnackModule } from '../../../apex/src/app/modules/snack.module';
import { UserService } from '../../../apex/src/app/services/user/user.service';
import { RaygunErrorHandler } from '../../../apex/src/app/utils/error-handler';
import { localeInitializer } from '../../../apex/src/app/utils/locale-init';
import { LocaleProvider } from '../../../apex/src/app/utils/locale-provider';
import { environment } from '../../../apex/src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SalesScreenInterceptor } from './interceptor';
import { SetupComponent } from './setup/setup.component';

@NgModule({
  declarations: [AppComponent, SetupComponent],
  imports: [
    AppRoutingModule,

    BrowserModule,
    HttpClientModule,
    FormsModule,
    BrowserAnimationsModule,

    SalesScreenModule,
    CasesModule,
    UnwantedIncidentFormModule,
    UnwantedIncidentConfirmationModule,
    FileUsageModule,
    FileUsageViewerModule,

    TranslateModule,

    MatInputModule,
    MatButtonModule,
    MatTableModule,
    MatProgressSpinnerModule,

    SnackModule,
    StatusServiceModule,
  ],
  providers: [
    HttpClient,
    ReportingService,
    UserService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SalesScreenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestStatusInterceptor,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useClass: RaygunErrorHandler,
    },
    LocaleProvider,
    {
      provide: APP_INITIALIZER,
      useValue: (): Promise<void> => Promise.resolve(localeInitializer(locale())),
      multi: true,
    },
    {
      provide: MAT_DATE_LOCALE,
      useValue: locale(),
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: MAT_MOMENT_DATE_FORMATS,
    },
    {
      provide: TINYMCE_SCRIPT_SRC,
      useValue: `${environment.appUrl}/tinymce/tinymce.min.js`,
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'outline',
      },
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
