import { Component, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription, firstValueFrom, mergeMap, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import {
  CreateUnwantedIncidentDto,
  MimeType,
  ResponseProjectUnwantedIncidentInfoDto,
  UnwantedIncidentType,
} from '../../../../../../../generated/apex-rest';
import { t } from '../../../components/translate/translate.function';
import { Profile } from '../../../models/profile';
import { UnwantedIncidentProjectInfo } from '../../../models/project';
import { snackErr } from '../../../modules/snack.module';
import { UserService } from '../../../services/user/user.service';
import {
  UnwantedIncidentMarkingDialogData,
  UnwantedIncidentMarkingDialogReturnData,
} from '../marking/unwanted-incident-marking-dialog-data.type';
import { UnwantedIncidentMarkingDialogComponent } from '../marking/unwanted-incident-marking-dialog.component';
import { UnwantedIncidentService } from '../unwanted-incident.service';

@Component({
  selector: 'apex-unwanted-incident-form',
  templateUrl: './unwanted-incident-form.component.html',
})
export class UnwantedIncidentFormComponent implements OnDestroy {
  unwantedIncident: CreateUnwantedIncidentDto;

  unwantedIncidentProjectInfo: ResponseProjectUnwantedIncidentInfoDto;

  unwantedIncidentFileKeys: string[] = [];

  personInjuryChecked = false;
  materialDamageChecked = false;
  accidentChecked = false;
  nearAccidentChecked = false;
  observationChecked = false;

  allowedMimeTypes: MimeType[] = [
    MimeType.ApplicationPdf,

    // Image
    MimeType.ImageJpeg,
    MimeType.ImagePng,
    MimeType.ImageWebp,

    // Video
    MimeType.VideoXMsvideo,
    MimeType.VideoMpeg,
    MimeType.VideoMp4,
    MimeType.VideoOgg,
    MimeType.VideoWebm,
  ];

  profile: Profile | null;
  projectId: number;

  private subscription = new Subscription();

  constructor(
    private route: ActivatedRoute,
    private service: UnwantedIncidentService,
    private userService: UserService,
    private router: Router,
    private dialog: MatDialog,
  ) {
    this.subscription.add(
      this.route.data
        .pipe(
          mergeMap(async (data) => {
            const unwantedIncident: CreateUnwantedIncidentDto = data.unwantedIncident;
            const projectInfo: UnwantedIncidentProjectInfo = data.projectInfo;

            this.profile = data.profile;
            this.unwantedIncident = unwantedIncident;
            this.unwantedIncidentProjectInfo = { ...projectInfo, addresses: [] };

            if (!this.profile) {
              this.profile = await firstValueFrom(this.userService.getProfile().pipe(catchError(() => of(null))));
            }
          }),
          map(() => {
            this.unwantedIncident.reporterName = this.profile?.name ?? '';
            this.unwantedIncident.reporterMobile = this.profile?.mobile ?? '';
            this.unwantedIncident.reporterEmail = this.profile?.mail ?? '';
            this.unwantedIncident.reporterCompany = this.profile?.Customer?.name ?? '';

            return this.unwantedIncident.ProjectId;
          }),
        )
        .subscribe(),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  async sendIn(): Promise<boolean> {
    this.unwantedIncident.types = [];
    this.unwantedIncident.fileKeys = this.unwantedIncidentFileKeys;

    if (this.accidentChecked) {
      this.unwantedIncident.types.push(UnwantedIncidentType.Accident);
    }

    if (this.materialDamageChecked) {
      this.unwantedIncident.types.push(UnwantedIncidentType.MaterialDamage);
    }

    if (this.personInjuryChecked) {
      this.unwantedIncident.types.push(UnwantedIncidentType.PersonInjury);
    }

    if (this.nearAccidentChecked) {
      this.unwantedIncident.types.push(UnwantedIncidentType.NearAccident);
    }

    if (this.observationChecked) {
      this.unwantedIncident.types.push(UnwantedIncidentType.Observation);
    }

    try {
      if (!this.profile) {
        await this.service.createUnwantedIncidentNonAuth(this.unwantedIncident);
      } else {
        await this.service.createUnwantedIncident(this.unwantedIncident);
      }
    } catch (err) {
      snackErr(t('Could not save'), err);

      return false;
    }

    if (this.unwantedIncident.ProjectId) {
      return this.router.navigate([
        '/',
        'project',
        this.unwantedIncident.ProjectId,
        'unwanted-incident',
        'confirmation',
      ]);
    }

    if (this.unwantedIncident.ObjectId) {
      return this.router.navigate(['/', 'object', this.unwantedIncident.ObjectId, 'unwanted-incident', 'confirmation']);
    }

    return false;
  }

  protected openMarkingDialog(): void {
    const dialogRef = this.dialog.open<
      UnwantedIncidentMarkingDialogComponent,
      UnwantedIncidentMarkingDialogData,
      UnwantedIncidentMarkingDialogReturnData
    >(UnwantedIncidentMarkingDialogComponent, {
      data: {
        info: this.unwantedIncidentProjectInfo,
        floorPlans: this.unwantedIncidentProjectInfo.floorPlans,
        markings: this.unwantedIncident.Markings,
      },
    });

    const close$ = dialogRef.afterClosed();
    const close$$ = close$.subscribe((result) => {
      if (result?.saveMarkings) {
        this.unwantedIncident.Markings = result.markings ?? [];
      }
    });

    this.subscription.add(close$$);
  }
}
