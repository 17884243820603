import { inject } from '@angular/core';

import { ResolveFn } from '@angular/router';
import { ReportingService } from '../reporting.service';
import { SalesScreenData } from './sales-screen.types';

export const SalesScreenResolve: ResolveFn<SalesScreenData> = () => {
  const service = inject(ReportingService);

  return service.salesScreen();
};
