<div class="df c ac">
  <form
    class="df c ac jsc"
    (ngSubmit)="onSubmit()"
    #setupForm="ngForm">
    <mat-form-field class="">
      <input
        matInput
        required
        name="customer"
        placeholder="Customer"
        type="number"
        [(ngModel)]="credentials.APEXCustomer" />
    </mat-form-field>

    <mat-form-field class="">
      <input
        matInput
        required
        name="key"
        placeholder="Key"
        type="text"
        [(ngModel)]="credentials.APEXKey" />
    </mat-form-field>

    <mat-form-field class="">
      <input
        matInput
        required
        name="secret"
        placeholder="Secret"
        type="password"
        [(ngModel)]="credentials.APEXSecret" />
    </mat-form-field>

    <div>
      <button
        type="submit"
        mat-raised-button
        [disabled]="!setupForm.form.valid">
        Save
      </button>
    </div>
  </form>
</div>
