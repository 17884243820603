import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Params, ResolveFn } from '@angular/router';
import {
  CreateUnwantedIncidentDto,
  CreateUnwantedIncidentProcessDto,
  ResponseUnwantedIncidentDto,
  ResponseUnwantedIncidentDtoPageResponse,
  ResponseUnwantedIncidentProcessActionDtoPageResponse,
  ResponseUnwantedIncidentProcessDto,
  ResponseUnwantedIncidentProcessDtoPageResponse,
  UnwantedIncidentProcessStatus,
} from '../../../../../../generated/apex-rest';
import { UnwantedIncidentService } from './unwanted-incident.service';

export const NewUnwantedIncidentResolver: ResolveFn<CreateUnwantedIncidentDto> = (route: ActivatedRouteSnapshot) => {
  const ProjectId = Number(route.paramMap.get('ProjectId')) || null;
  const ObjectId = Number(route.paramMap.get('ObjectId')) || null;

  return {
    reporterCompany: null,
    reporterEmail: null,
    reporterMobile: null,
    reporterName: null,
    incident: null,
    immediateAction: null,
    types: [],
    fileKeys: [],
    ProjectId,
    ObjectId,
  };
};

export const UnwantedIncidentResolver: ResolveFn<ResponseUnwantedIncidentDto> = (route: ActivatedRouteSnapshot) => {
  const service = inject(UnwantedIncidentService);

  const unwantedIncidentId = route.paramMap.get('unwantedIncidentId');

  return service.readUnwantedIncident(unwantedIncidentId);
};

export const UnwantedIncidentsResolver: ResolveFn<ResponseUnwantedIncidentDtoPageResponse> = (
  route: ActivatedRouteSnapshot,
) => {
  const service = inject(UnwantedIncidentService);

  const {
    unwantedIncidentSearch,
    unwantedIncidentProjectId,
    unwantedIncidentObjectId,
    unwantedIncidentType,
    limit,
    page,
    orderBy,
    order,
  } = route.queryParams;

  const params: Params = {
    orderBy: orderBy ?? 'createdAt',
    order: order ?? 'DESC',
    page: page ?? '0',
    limit: limit ?? '25',
    search: unwantedIncidentSearch ?? '',

    projectId: unwantedIncidentProjectId,
    objectId: unwantedIncidentObjectId,
    types: unwantedIncidentType ?? [],
  };

  return service.readUnwantedIncidents(...Object.values(params));
};

export const NewUnwantedIncidentProcessResolver: ResolveFn<CreateUnwantedIncidentProcessDto> = () => ({
  notes: null,
  status: UnwantedIncidentProcessStatus.New,
});

export const UnwantedIncidentProcessResolver: ResolveFn<ResponseUnwantedIncidentProcessDto> = (
  route: ActivatedRouteSnapshot,
) => {
  const service = inject(UnwantedIncidentService);

  const unwantedIncidentId = route.paramMap.get('unwantedIncidentId');
  const unwantedIncidentProcessId = route.paramMap.get('unwantedIncidentProcessId');

  return service.readUnwantedIncidentProcess(unwantedIncidentId, unwantedIncidentProcessId);
};

export const UnwantedIncidentProcessesResolver: ResolveFn<ResponseUnwantedIncidentProcessDtoPageResponse> = (
  route: ActivatedRouteSnapshot,
) => {
  const service = inject(UnwantedIncidentService);

  const unwantedIncidentId = route.paramMap.get('unwantedIncidentId');
  const { unwantedIncidentProcessSearch, limit, page, orderBy, order } = route.queryParams;

  const params: Params = {
    orderBy: orderBy ?? 'createdAt',
    order: order ?? 'DESC',
    page: page ?? '0',
    limit: limit ?? '25',
    search: unwantedIncidentProcessSearch ?? '',
  };

  return service.readUnwantedIncidentProcesses(unwantedIncidentId, ...Object.values(params));
};

export const UnwantedIncidentProcessActionsResolver: ResolveFn<ResponseUnwantedIncidentProcessActionDtoPageResponse> = (
  route: ActivatedRouteSnapshot,
) => {
  const service = inject(UnwantedIncidentService);

  const unwantedIncidentId = route.paramMap.get('unwantedIncidentId');
  const unwantedIncidentProcessId = route.paramMap.get('unwantedIncidentProcessId');

  const { unwantedIncidentProcessActionSearch, limit, page, orderBy, order } = route.queryParams;

  const params: Params = {
    orderBy: orderBy ?? 'createdAt',
    order: order ?? 'ASC',
    page: page ?? '0',
    limit: limit ?? '25',
    search: unwantedIncidentProcessActionSearch ?? '',
  };

  return service.readUnwantedIncidentProcessActions(
    unwantedIncidentId,
    unwantedIncidentProcessId,
    ...Object.values(params),
  );
};
