import { Pipe, PipeTransform } from '@angular/core';

/**
 * @description
 *
 * Transforms something into a string, formatted with spaces and an optional
 * separator.
 *
 * Default spacing is `3` and ` ` (space) as separator.
 */
@Pipe({
  name: 'space',
})
export class SpacePipe implements PipeTransform {
  /**
   *
   * @param input The string to be formatted with spaces.
   * @param digits How many digits to group.
   * Default is `3`.
   * @param separator What separator to use.
   * Default is `-`
   */
  transform(input: string | number, digits?: number, separator?: string): string {
    if (input === null) {
      return '';
    }

    if (!digits || isNaN(digits)) {
      digits = 3; // default
    }

    separator = separator || ' '; // default

    let output = '';
    const inputString = String(input);

    for (let i = inputString.length - 1, j = 0; i > -1; ) {
      if (j === digits) {
        output += separator;
        j = 0;
      }

      output += inputString[i];

      i = i - 1;
      j = j + 1;
    }

    return output.split('').reverse().join('');
  }
}
